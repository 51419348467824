import { ImgHTMLAttributes } from "react";
import styled from "styled-components";

export const ModalInnerContainer = styled.div<any>`
    border-radius: 12px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
    background-color: #ffffff;
`;

export const ModalCloseIcon = styled.img.attrs((props: Pick<Readonly<ImgHTMLAttributes<HTMLImageElement>>, "onClick">) => ({
        onClick: props.onClick
    }))<any>`
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin: 16px;
    cursor: pointer;
    position: absolute;
    right: 0;
`;

export const ContentPage = styled.div`
    padding: 50px;
    background-color: ${({ theme }) => theme.colors.background};
    flex: 1;
    display: flex;
    flex-direction: column;
    white-space: pre-line;
`;
